<template>

  <template v-if="video_data">
    <!-- {{ video_data }} -->
    <!-- autoplay="false"  -->


    <div v-if="!video_data.is_ready" class="video_empty_working" >
      <img 
      :src="video_data.item_photo.size_m" 
      class="cover" 
      alt="">

      <div class="place">
        Video is being processed <i class="cloading"></i>
      </div>

    </div>

    <video 
      v-else
      class="vjs-tech" 
      autoplay="true"
      controls="true"
      preload="auto" 
      playsinline="playsinline"   
      :poster="video_data.item_photo.size_m"
      >
      <source :src="video_data.url" type="video/mp4">      
    </video>


    <div class="copylink"></div>

    <div class="embed" @click="share = true"></div>

    <a target="_blank" :href="video_data.item_photo.link_week" class="logo"></a>

    <div v-if="share" class="embedWindow">
      <textarea 
        ref="code" 
        id="code_frame" 
        class="textarea_embed"><iframe width="640" height="480" frameborder="0" allowfullscreen mozallowfullscreen webkitallowfullscreen src="https://growdiaries.com/video/{{video_data.id}}"></iframe>
      </textarea>
      <div class="btns">
        <button @click="copy" class="btn_close_copy">Copy link</button>
        <button @click="share = false" class="btn_close_embed">Close</button>
      </div>
    </div>

    
     

  </template>

</template>

<script setup>


definePageMeta({
  layout: "empty",
  auth: false
})

const route = useRoute();
const { $api, $ga, $helper, $head } = useNuxtApp();
var video_id = ref(route.params['id']);
var share = ref(false);
const code = ref(null);

const loadData = async function() {   
  const response = await $api.getVideo(video_id.value)  
  return response;  
}

const copy = function(){
  code.value.select();
  document.execCommand('copy');
}


const { pending: is_loading,  data: video_data } = await useLazyAsyncData('video_data', async () => await loadData())


// useHead(useNuxtApp().$head.getVideo(video_data));



</script>

<style scoped>

html,
body{
  width: 100%;
  height: 100%!important;
  margin: 0px;
  padding: 0px;
  background-color: black;
}

.video_empty{
  width: 100%;
  height: 50px;
  display: block;
  /* width: 300px; */
  /* height: 40px; */
  margin: auto auto;
  vertical-align: middle;
  text-align: center;
  padding-top: 33%;
  color: white;
}
.video_empty_working{
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  margin: auto auto;

}
.video_empty_working .cover{
  width: 100%;
  height: 100%;
  object-fit: contain;
  margin: auto auto;
}
.video_empty_working .place{
  position: absolute;
  color: white;
  font-size: 1.1rem;
  font-weight: 300;
  text-align: center;
  font-family: var(--gd-text-font-family);
  text-shadow: 0 0 13px BLACK;
}
.video_empty_working .place .cloading{
  position: relative;
  font-size: 0.8rem;
  top: initial;
  left: initial;
  width: 20px;
  display: inline-block;
  vertical-align: middle;
}
.video_empty_working .place .cloading::before{

}
.video_empty_working .place .cloading::after{
  display: none;
}
.video_empty_working{
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  margin: auto auto;

}
.video_empty_working .cover{
  width: 100%;
  height: 100%;
  object-fit: contain;
  margin: auto auto;
}
.video_empty_working .place{
  position: absolute;
  color: white;
  font-size: 1.1rem;
  font-weight: 300;
  text-align: center;
  font-family: var(--gd-text-font-family);
  text-shadow: 0 0 13px BLACK;
}
.video_empty_working .place .cloading{
  position: relative;
  font-size: 0.8rem;
  top: initial;
  left: initial;
  width: 20px;
  display: inline-block;
  vertical-align: middle;
}
.video_empty_working .place .cloading::before{

}
.video_empty_working .place .cloading::after{
  display: none;
}
.embed{

    position: fixed;
    right: 56px;
    top: 10px;
    z-index: 11040; 
    background-color: rgba(0, 0, 0, 0.47);
    border-radius: 5px;
    background-repeat: no-repeat;
    display: inline-block;
    background-size: 100%;
    padding: 20px;
    background-image: url("/images/player/embed.png");
    background-size: 80%;
    background-position: 50% 50%;
}
.embed:hover{ 
    background-color: black;
}
.copylink{

    position: fixed;
    right: 10px;
    top: 10px;
    z-index: 11040; 
    background-color: rgba(0, 0, 0, 0.47);
    border-radius: 5px;
    background-repeat: no-repeat;
    display: inline-block;
    background-size: 100%;
    padding: 20px;
    background-image: url("/images/player/link.png");
    background-size: 80%;
    background-position: 50% 50%;
}
.copylink:hover{
    background-color: black;
}
.logo{

    position: fixed;
    right: 102px;
    top: 10px;
    z-index: 11040;
    width: 150px;
    height: 13px;
    background-color: rgba(0, 0, 0, 0.47);
    border-radius: 5px;
    background-repeat: no-repeat;
    display: inline-block;
    background-size: 90%; 
    padding: 20px;
    background-position: 50% 50%;
    background-image: url("/logo/logo_white.png");
}
.logo:hover{
    background-color: black;
}


.embedWindow{
  position: fixed;  
  background-color: rgba(0, 0, 0, 0.72);
  width: calc(300px);
  padding: 20px;
  top: calc(50% - 100px);
  border-radius: 5px;
  left: calc(50% - 150px);
  display: flex;
  gap: 1rem;
  flex-direction: column;
}
.textarea_embed{
  width: 100%;
  border-radius: 5px;
  height: 100px;
  font-size: 1rem;
  font-weight: 100;
}
.btns{
  display: flex;
  gap: 1rem;
  width: 100%;
}
.btn_close_embed{
  width: calc(50% - 0.5rem);
  padding: 10px;
  background-color: #6c6c6c;
  border: 0px;
  border-radius: 3px;
  color: white;
  display: inline-block;
  flex-shrink: 0;
}
.btn_close_copy{
  width: calc(50% - 0.5rem);
  padding: 10px;
  background-color: var(--un-primary-back-color);
  border: 0px;
  border-radius: 3px;
  color: white;
  display: inline-block;
  flex-shrink: 0;
}
.embedWindow.open{
  display: inline-block;
}

/* video */


video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; 
  background-color: black;
}

 
</style>